.hero{
    display: flex;
    justify-content: space-between;
}
.right-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.left-h{
    flex: 1;
    position: relative;
    background-color: #F86F15;
}
.header-menu>li:hover{
    cursor: pointer;
    color: #F86F15;
    cursor: pointer;
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    width: fit-content;
    border-radius: 4rem;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
    background-color: #F86F15;
    width: 5.4rem;
    height: 80%;
    right: 8px;
    z-index: 1;
    border-radius: 3rem;

}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200rem;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    text-transform: uppercase;
    color: var(--gray);
}
.hero-buttons{
    display: flex;
    gap:1rem;
    font-weight: normal;
}
.hero-buttons>:nth-child(1){
    color: #3F4441;
    background-color: #F86F15;
    width: 8rem;
}
.hero-buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid #F86F15;
}
.left-h>.btn{
    position: absolute;
    color: black;
    left: 3rem;
    top: 2rem;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    left: 4rem;
    top: 7rem;
}
.heart-rate>img{
    width: 2rem;
}
.heart-rate>:nth-child(2){
    color: var(--grey);
}
.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    top: 16rem;
    left: 10rem;
    width: 23rem;
}
.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 32rem;
    left: 30rem;
}
.calories>img{
    width: 4rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div:nth-child(1){
    color: var(--grey);
}
.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}
.hero-blur{
    width: 22rem;
    height: 30rem;
    right: 0;
}
@media only screen and (max-width: 768px)
      {
        .hero{
            flex-direction: column;
        }
        .hero-blur{
            width: 14rem;
        }
        .the-best-ad{
            margin-top: 0;
            font-size: small;
            align-self: center;
            transform: scale(0.8);
        }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-buttons{
        justify-content: center;
    }
    .figures{
        align-self: center;
    }
    .figures>div>span:nth-of-type(1){
        font-size: large;
    }
    .figures>div>span:nth-of-type(2){
        font-size: small;
    }
.right-h{
    background: none;
    position: relative;
    
}
.heart-rate{
    display: none;
    /* right: 1rem;

    top: 2rem; */
}
.calories{
    display: none;
    /* position: relative;
    top: 5rem;
    right: 2rem; */
}
.calories>img{
    display: none;
    /* width: 2rem; */
}
.calories>div>:nth-child(2){
    display: none;
    /* color: white;
    font-size: 1rem; */
}
.left-h>.btn{
    display: none; 
}
.hero-image{
    display: none;
    /* position: relative;
width: 15rem;

right: 7rem;
top: 0.5rem;

align-self: center; */
}
}