.testimonials{
    gap: 2rem;
    display: flex;
    padding: 0 2rem;
}
.right-t{
    display: flex;
    gap: 1rem;
    flex: 1 1;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.right-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.right-t>:nth-child(2),
.right-t>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.right-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.left-t{
   position: relative;
    flex: 1;
}
.left-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    left: 8rem;
    top: 2rem;
}
.left-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    left:9rem;
    top: 0.9rem;
}
.left-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    left: 7rem;
    top: 4rem;
    background: var(--planCard);
}
.arrows{display: flex;
gap: 1rem;
position: absolute;
bottom: 1rem;
right: 3rem;}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}
@media only screen and (max-width: 768px){
    .testimonials{
        flex-direction: column;
    }
    .right-t>:nth-child(2),
.right-t>:nth-child(3){
    font-size: xx-large;
}
.left-t{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.left-t>div{
    position: relative;
    display: none;
}
.left-t>img{
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
}
.left-t>:last-child{
    display: block;
}
}